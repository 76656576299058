// Sidebar.js
import React from "react";
import { Nav } from "react-bootstrap";
import "./Sidebar.css";

const Sidebar = ({ show, setActivePage }) => {
  return (
    <div className={`sidebar ${show ? "show" : ""}`}>
      <Nav className="flex-column">
        <Nav.Link onClick={() => setActivePage("home")}>Home</Nav.Link>
        <Nav.Link onClick={() => setActivePage("sales")}>
          Business Partnership
        </Nav.Link>
        <Nav.Link onClick={() => setActivePage("invoice")}>Quotation</Nav.Link>
        <Nav.Link onClick={() => setActivePage("orders")}>
          Purchase Orders
        </Nav.Link>
        <Nav.Link onClick={() => setActivePage("finance")}>
          Financial Partnership
        </Nav.Link>
        <Nav.Link onClick={() => setActivePage("letterOfCredit")}>
          Letter of Credit
        </Nav.Link>
        <Nav.Link onClick={() => setActivePage("documents")}>
          Documentation
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
