import { getBlockchain } from "./ethereum";

export const setSalesAgreement = async (seller, orderId) => {
  try {
    const { applicantContract } = await getBlockchain();
    if (applicantContract) {
      console.log(seller);
      console.log(orderId);
      const tx = await applicantContract.setSalesAgreement(seller, orderId);
      console.log("Transaction sent:", tx.hash);
      await tx.wait();
      console.log("Sales agreement set:", tx);
      return { status: "success" };
    } else {
      throw new Error("Contract not found.");
    }
  } catch (error) {
    console.error("Error setting sales agreement:", error);
    return { status: "error", error: error.message };
  }
};

export const addOrder = async (orderId, quantity, item, description) => {
  try {
    const { applicantContract } = await getBlockchain();
    if (applicantContract) {
      const tx = await applicantContract.addOrder(
        orderId,
        quantity,
        item,
        description
      );
      await tx.wait();
      console.log("Order added:", tx);
      return { status: "success" };
    } else {
      throw new Error("Contract not found.");
    }
  } catch (error) {
    console.error("Error adding order:", error);
    return { status: "error", error: error.message };
  }
};

export const createInvoice = async (
  orderId,
  unitPrice,
  discount,
  quantity,
  privateKey
) => {
  try {
    const { sellerContract } = await getBlockchain();
    if (sellerContract) {
      const tx = await sellerContract.createInvoice(
        orderId,
        unitPrice,
        discount,
        quantity
      );
      console.log("Transaction sent:", tx.hash);
      await tx.wait();
      console.log("Invoice created:", tx);
      return { status: "success" };
    } else {
      throw new Error("Contract not found.");
    }
  } catch (error) {
    console.error("Error creating invoice:", error);
    return { status: "error", error: error.message };
  }
};

export const confirmInvoice = async (orderId, privateKey) => {
  try {
    const { applicantContract } = await getBlockchain();
    if (applicantContract) {
      const tx = await applicantContract.confirmInvoice(orderId);
      console.log("Transaction sent:", tx.hash);
      await tx.wait();
      console.log("Invoice confirmed:", tx);
      return { status: "success" };
    } else {
      throw new Error("Contract not found.");
    }
  } catch (error) {
    console.error("Error confirming invoice:", error);
    return { status: "error", error: error.message };
  }
};

export const confirmOrder = async (orderId, privateKey) => {
  try {
    const { sellerContract } = await getBlockchain();
    if (sellerContract) {
      const tx = await sellerContract.confirmOrder(orderId);
      console.log("Transaction sent:", tx.hash);
      await tx.wait();
      console.log("Order confirmed:", tx);
      return { status: "success" };
    } else {
      throw new Error("Contract not found.");
    }
  } catch (error) {
    console.error("Error confirming order:", error);
    return { status: "error", error: error.message };
  }
};

export const orderExists = async (orderId) => {
  try {
    const { sellerContract } = await getBlockchain();
    if (sellerContract) {
      // Convert orderId to bytes32 format if necessary
      //const bytes32OrderId = new ethers.formatBytes32String(orderId);
      const exists = await sellerContract.checkOrder(orderId);
      const serializedOrder = {
        exists: exists[0],
      };
      return exists;
    }
  } catch (error) {
    console.error("Error checking if order exists:", error);
    throw error;
  }
};

export const viewInvoices = async (orderId) => {
  try {
    const { sellerContract } = await getBlockchain();
    if (sellerContract) {
      console.log(orderId);
      const invoice = await sellerContract.viewInvoices(orderId);

      const serializedInvoice = {
        unitPrice: Number(invoice[0]).toFixed(2),
        totalCost: Number(invoice[1]).toFixed(2),
        discount: Number(invoice[2]),
        costAfterDiscount: Number(invoice[3]).toFixed(2),
        isConfirmed: invoice[4],
      };

      return serializedInvoice;
    }
  } catch (error) {
    console.error("Error viewing invoices:", error);
    throw error;
  }
};

// Function to view dealers
export const viewDealers = async (orderId) => {
  try {
    const { sellerContract } = await getBlockchain();
    if (sellerContract) {
      //const bytes32OrderId = new ethers.formatBytes32String(orderId);
      const dealers = await sellerContract.viewDealers(orderId);
      const serializeddealers = {
        seller: dealers[0],
        buyer: dealers[1],
        orderId: dealers[2],
        isDeal: dealers[3],
      };
      return serializeddealers;
    }
  } catch (error) {
    console.error("Error viewing dealers:", error);
    throw error;
  }
};

// Function to view orders
export const viewOrders = async (orderId) => {
  try {
    const { sellerContract } = await getBlockchain();
    if (sellerContract) {
      //const bytes32OrderId = ethers.formatBytes32String(orderId);
      const orders = await sellerContract.viewOrders(orderId);
      const serializedOrders = {
        orderID: orders[0],
        seller: orders[1],
        buyer: orders[2],
        item: orders[3],
        orderState: orders[4],
        paymentState: orders[5],
        description: orders[6],
        quantity: orders[7],
        isVerifiedByInspectionCompany: orders[8],
        isVerifiedBySellerCountryCustoms: orders[9],
        isVerifiedByBuyerCountryCustoms: orders[10],
        isOrder: orders[11],
      };
      console.log(orders);
      console.log(serializedOrders);
      return serializedOrders;
    }
  } catch (error) {
    console.error("Error viewing orders:", error);
    throw error;
  }
};

export const setFinancialAgreementParties = async (orderId, issuingBank) => {
  try {
    const { applicantContract } = await getBlockchain();
    if (applicantContract) {
      const tx = await applicantContract.setFinancialAgreementParties(
        orderId,
        issuingBank
      );
      await tx.wait();
      console.log("Financial agreement parties set:", tx);
      return { status: "success" };
    } else {
      throw new Error("Contract not found.");
    }
  } catch (error) {
    console.error("Error setting financial agreement parties:", error);
    return { status: "error", error: error.message };
  }
};

// Function to confirm financial agreement
export const confirmFinancialAgreement = async (orderId) => {
  try {
    const { issuingBankContract } = await getBlockchain();
    if (issuingBankContract) {
      //const bytes32OrderId = ethers.utils.formatBytes32String(orderId);
      const tx = await issuingBankContract.confirmFinancialAgreement(orderId);
      await tx.wait();
      console.log("Financial agreement confirmed:", tx);
      return { status: "success" };
    }
  } catch (error) {
    console.error("Error confirming financial agreement:", error);
    return { status: "error", error: error.message };
  }
};

// Function to set LC Agreement
export const setLCAgreement = async (seller, correspondingBank, orderId) => {
  try {
    const { issuingBankContract } = await getBlockchain();
    if (issuingBankContract) {
      //const bytes32OrderId = ethers.utils.formatBytes32String(orderId);
      const tx = await issuingBankContract.setLCAgreement(
        seller,
        correspondingBank,
        orderId
      );
      await tx.wait();
      console.log("LC Agreement set:", tx);
      return { status: "success" };
    }
  } catch (error) {
    console.error("Error setting LC Agreement:", error);
    return { status: "error", error: error.message };
  }
};

// Function to add Document
export const addDocument = async (orderId, documentId) => {
  try {
    const { issuingBankContract } = await getBlockchain();
    if (issuingBankContract) {
      //const bytes32OrderId = ethers.utils.formatBytes32String(orderId);
      //const bytes32DocumentId = ethers.utils.formatBytes32String(documentId);
      const tx = await issuingBankContract.addDocument(orderId, documentId);
      await tx.wait();
      console.log("Document added:", tx);
      return { status: "success" };
    }
  } catch (error) {
    console.error("Error adding document:", error);
    return { status: "error", error: error.message };
  }
};

// Function to get Number of Documents
export const getNumberOfDocuments = async (orderId) => {
  try {
    const { issuingBankContract } = await getBlockchain();
    if (issuingBankContract) {
      //const bytes32OrderId = ethers.utils.formatBytes32String(orderId);
      const numberOfDocuments = await issuingBankContract.getNumberOfDocuments(
        orderId
      );
      return numberOfDocuments;
    }
  } catch (error) {
    console.error("Error getting number of documents:", error);
    throw error;
  }
};

// Function to get Document IDs
export const getDocumentID = async (orderId) => {
  try {
    const { correspondingBankContract } = await getBlockchain();
    if (correspondingBankContract) {
      //const bytes32OrderId = ethers.utils.formatBytes32String(orderId);
      const documentIDs = await correspondingBankContract.getDocumentID(
        orderId
      );
      console.log("Document IDs:", documentIDs);
      const serializedDocumentIDs = {
        numberOfDocs: Number(documentIDs[0]),
      };
      return documentIDs;
    }
  } catch (error) {
    console.error("Error getting document IDs:", error);
    throw error;
  }
};

// Function to check if Document is Valid
export const isDocumentValid = async (documentId) => {
  try {
    const { contract } = await getBlockchain();
    if (contract) {
      //const bytes32DocumentId = ethers.utils.formatBytes32String(documentId);
      const isValid = await contract.isDocumentValid(documentId);
      console.log("Is document valid:", isValid);
      return isValid;
    }
  } catch (error) {
    console.error("Error checking document validity:", error);
    throw error;
  }
};

// Function to validate Document
export const validateDocument = async (orderId, documentId) => {
  try {
    const { correspondingBankContract } = await getBlockchain();
    if (correspondingBankContract) {
      //const bytes32OrderId = ethers.utils.formatBytes32String(orderId);
      //const bytes32DocumentId = ethers.utils.formatBytes32String(documentId);
      const tx = await correspondingBankContract.validateDocument(
        orderId,
        documentId
      );
      await tx.wait();
      console.log("Document validated:", tx);
      return { status: "success" };
    }
  } catch (error) {
    console.error("Error validating document:", error);
    return { status: "error", error: error.message };
  }
};
//0xabbb5caa7dda850e60932de0934eb1f9d0f59695050f761dc64e443e5030a501
