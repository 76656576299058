import { Tabs, Tab } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import SalesAgreementForm from "./components/SalesAgreementForm";
import AddOrderForm from "./components/AddOrderForm";
import CreateInvoiceForm from "./components/CreateInvoiceForm";
import ConfirmInvoiceForm from "./components/ConfirmInvoiceForm";
import ConfirmOrderForm from "./components/ConfirmOrderForm";
import CheckOrderForm from "./components/CheckOrderForm";
import ViewInvoicesForm from "./components/ViewInvoicesForm";
import ViewDealersForm from "./components/ViewDealersForm";
import ViewOrdersForm from "./components/ViewOrdersForm";
import SetFinancialAgreementPartiesForm from "./components/SetFinancialAgreementPartiesForm";
import ConfirmFinancialAgreementForm from "./components/ConfirmFinancialAgreementForm";
import SetLCAgreementForm from "./components/SetLCAgreementForm";
import AddDocumentForm from "./components/AddDocumentForm";
import GetNumberOfDocumentsForm from "./components/GetNumberOfDocumentsForm";
import GetDocumentIDForm from "./components/GetDocumentIDForm";
import ValidateDocumentForm from "./components/ValidateDocumentForm";
import Sidebar from "./Sidebar"; // Import the Sidebar component
import logo from "./assets/logo.svg"; // Import the logo
import "./App.css";

const App = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [activePage, setActivePage] = useState("home");
  const [orderId, setOrderId] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [POData, setPOData] = useState([]);
  const [LCData, setLCData] = useState([]);
  const [FinanceData, setFinanceData] = useState([]);
  const [DocumentData, setDocumentData] = useState([]);

  const handleToggle = () => setShowSidebar(!showSidebar);

  useEffect(() => {
    // Get OrderId and Order Data from localStorage
    const storedOrderId = JSON.parse(localStorage.getItem("OrderId"));
    const storedOrderData = JSON.parse(localStorage.getItem("OrderData")) || [];
    const storedPOData = JSON.parse(localStorage.getItem("POData")) || [];
    const storedLCData = JSON.parse(localStorage.getItem("LCData")) || [];
    const storedFinanceData =
      JSON.parse(localStorage.getItem("FinanceData")) || [];
    const storedDocumentData =
      JSON.parse(localStorage.getItem("DocumentData")) || [];

    setOrderId(storedOrderId);
    setOrderData(storedOrderData);
    setPOData(storedPOData);
    setLCData(storedLCData);
    setFinanceData(storedFinanceData);
    setDocumentData(storedDocumentData);
  }, []);

  const renderContent = () => {
    switch (activePage) {
      case "home":
        return (
          <div className="bodyText">
            <center>
              <h2>What is a Letter of Credit?</h2>
            </center>
            <br />
            <br />
            <p>
              A letter of credit or LC is a written document issued by the
              importer’s bank (opening bank) on importer’s behalf. Through its
              issuance, the exporter is assured that the issuing bank will make
              a payment to the exporter for the international trade conducted
              between both the parties. The importer is the applicant of the LC,
              while the exporter is the beneficiary. In an LC, the issuing bank
              promises to pay the mentioned amount as per the agreed timeline
              and against specified documents. A guiding principle of an LC is
              that the issuing bank will make the payment based solely on the
              documents presented, and they are not required to physically
              ensure the shipping of the goods. If the documents presented are
              in accord with the terms and conditions of the LC, the bank has no
              reason to deny the payment.
            </p>

            <div className="row">
              <h2 style={{ color: "green" }}>Active Order: {orderId}</h2>
              <br />
              <div className="container mt-4">
                <div className="mt-4 p-4 border rounded bg-light">
                  <h2 style={{ color: "green" }}>Quotations</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>OrderId</th>
                          {/* <th>Product</th> */}
                          <th>Unit Price</th>
                          <th>Discount</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.orderId}</td>
                            {/* <td>{row.product}</td> */}
                            <td>{row.unitPrice}</td>
                            <td>{row.discount}</td>
                            <td>{row.quantity}</td>
                            <td>{row.total}</td>
                            <td>{row.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <br />
              <div className="container mt-4">
                {/* Purchase Orders Table */}
                <div className="table-section">
                  <h2 style={{ color: "green" }}>Purchase Orders</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>OrderId</th>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Description</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {POData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.orderId}</td>
                            <td>{row.item}</td>
                            <td>{row.quantity}</td>
                            <td>{row.description}</td>
                            <td>{row.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <br />
              <div className="container mt-4">
                {/* Letter of Credit Table */}
                <div className="table-section">
                  <h2 style={{ color: "green" }}>Letter of Credit</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>OrderId</th>
                          <th>Buyer</th>
                          <th>Seller</th>
                          <th>Corresponding Bank</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LCData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.orderId}</td>
                            <td>{row.buyer}</td>
                            <td>{row.seller}</td>
                            <td>{row.correspondingBank}</td>
                            <td>{row.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <br />
              <div className="container mt-4">
                {/* Financial Agreement Table */}
                <div className="table-section">
                  <h2 style={{ color: "green" }}>Financial Agreement</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>OrderId</th>
                          <th>Issuing Bank</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {FinanceData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.orderId}</td>
                            <td>{row.issuingBank}</td>
                            <td>{row.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <br />
              <div className="container mt-4">
                {/* Documents Table */}
                <div className="table-section">
                  <h2 style={{ color: "green" }}>Documents</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>OrderId</th>
                          <th>Number of Documents Uploaded</th>
                          <th>Document Ids</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {DocumentData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.orderId}</td>
                            <td>{row.numberOfDocs}</td>
                            <td>{row.documentId}</td>
                            <td>{row.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "sales":
        return <SalesAgreementForm />;
      case "invoice":
        return (
          <Tabs defaultActiveKey="createInvoice" id="form-tabs">
            <Tab eventKey="createInvoice" title="Create">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <CreateInvoiceForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="confirmInvoice" title="Confirm">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <ConfirmInvoiceForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="viewInvoices" title="View">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <ViewInvoicesForm />
                </div>
              </div>
            </Tab>
          </Tabs>
        );
      case "orders":
        return (
          <Tabs defaultActiveKey="addOrder" id="order-tabs">
            <Tab eventKey="addOrder" title="Add">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <AddOrderForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="confirmOrder" title="Confirm">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <ConfirmOrderForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="checkOrder" title="Check">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <CheckOrderForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="viewOrders" title="View PO">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <ViewOrdersForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="viewDealers" title="View Partners">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <ViewDealersForm />
                </div>
              </div>
            </Tab>
          </Tabs>
        );

      case "finance":
        return (
          <Tabs
            defaultActiveKey="setAgreementParties"
            id="financial-agreement-tabs"
          >
            <Tab eventKey="setAgreementParties" title="Add">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <SetFinancialAgreementPartiesForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="confirmAgreement" title="Confirm">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <ConfirmFinancialAgreementForm />
                </div>
              </div>
            </Tab>
          </Tabs>
        );
      case "letterOfCredit":
        return <SetLCAgreementForm />;
      case "documents":
        return (
          <Tabs defaultActiveKey="addDocument" id="document-tabs">
            <Tab eventKey="addDocument" title="Add">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <AddDocumentForm />
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="getNumberOfDocuments"
              title="Get Number of Documents"
            >
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <GetNumberOfDocumentsForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="getDocumentID" title="Get Document ID">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <GetDocumentIDForm />
                </div>
              </div>
            </Tab>
            <Tab eventKey="validateDocument" title="Validate">
              <div className="row mt-12">
                <div className="col-md-12 mb-3">
                  <ValidateDocumentForm />
                </div>
              </div>
            </Tab>
          </Tabs>
        );
      default:
        return <div>Select a page from the sidebar</div>;
    }
  };

  return (
    <div className="App">
      <Sidebar show={showSidebar} setActivePage={setActivePage} />

      <div
        className={`content ${
          showSidebar ? "with-sidebar" : "without-sidebar"
        }`}
      >
        <Button
          variant="primary"
          onClick={handleToggle}
          className="burger-menu"
        >
          ☰
        </Button>
        <div className="header">
          <img src={logo} alt="Logo" className="img-fluid logo" />
          <h1 className="mt-2 title">Accepire Letter of Credit (MVP)</h1>
        </div>
        <div className="main-content">{renderContent()}</div>
      </div>
    </div>
  );
};

export default App;
