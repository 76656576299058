import React, { useState, useEffect } from "react";
import { setSalesAgreement } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";

const generateOrderId = () => {
  const bytes = new Uint8Array(32);
  window.crypto.getRandomValues(bytes);
  const orderId = `0x${Array.from(bytes)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("")}`;
  localStorage.setItem("OrderId", JSON.stringify(orderId));
  return orderId;
};

const SalesAgreementForm = () => {
  const [seller, setSeller] = useState("");
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);

  const predefinedAddresses = [
    {
      label: "Seller 1 (ABC Electronics)",
      value: "0xCc4C6048F1c3215EDa31BACBBFeD320edEf8Beb2",
    },
    {
      label: "Seller 2 (DEF Pvt. Ltd.)",
      value: "0xCc4C6048F1c3215EDa31BACBBFeD320edEf8Beb2",
    },
    {
      label: "Seller 1 (MNO Traders)",
      value: "0xCc4C6048F1c3215EDa31BACBBFeD320edEf8Beb2",
    },
  ];

  useEffect(() => {
    setOrderId(generateOrderId());
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setTransactionStatus(null);

    try {
      const response = await setSalesAgreement(seller, orderId);
      if (response && response.status === "success") {
        setTransactionStatus("success");
      } else {
        setTransactionStatus("error");
      }
    } catch (error) {
      console.error("Error setting sales agreement:", error);
      setTransactionStatus("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Business Partnership</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="seller">Ethereum Address:</label>
              <select
                className="form-control"
                id="seller"
                value={seller}
                onChange={(e) => setSeller(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select partner address
                </option>
                {predefinedAddresses.map((address) => (
                  <option key={address.value} value={address.value}>
                    {address.label}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={loading}
            >
              {loading ? "Processing..." : "Add"}
            </button>
          </form>

          {loading && (
            <div className="progress mt-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              ></div>
            </div>
          )}

          {transactionStatus === "success" && (
            <div className="alert alert-success mt-3" role="alert">
              Transaction successful! Order ID: {orderId}
            </div>
          )}
          {transactionStatus === "error" && (
            <div className="alert alert-danger mt-3" role="alert">
              Transaction failed. Please try again.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesAgreementForm;
