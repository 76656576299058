import React, { useState } from "react";
import { setLCAgreement } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";

const SetLCAgreementForm = () => {
  const [seller, setSeller] = useState("");
  const [correspondingBank, setCorrespondingBank] = useState("");
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const sellers = [
    {
      label: "Seller 1 (ABC Electronics)",
      value: "0xCc4C6048F1c3215EDa31BACBBFeD320edEf8Beb2",
    },
    {
      label: "Seller 2 (DEF Pvt. Ltd.)",
      value: "0xCc4C6048F1c3215EDa31BACBBFeD320edEf8Beb2",
    },
    {
      label: "Seller 1 (MNO Traders)",
      value: "0xCc4C6048F1c3215EDa31BACBBFeD320edEf8Beb2",
    },
  ];

  const banks = [
    {
      value: "0x28000ddc8478C81633421bEF8E385394c6409DAD",
      label: "Corresponding Bank A",
    },
    {
      value: "0x28000ddc8478C81633421bEF8E385394c6409DAD",
      label: "Corresponding Bank B",
    },
    {
      value: "0x28000ddc8478C81633421bEF8E385394c6409DAD",
      label: "Corresponding Bank C",
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setTransactionStatus(null);

    try {
      const result = await setLCAgreement(seller, correspondingBank, orderId);
      if (result && result.status === "success") {
        setTransactionStatus("success");
        const buyer = "0x810C3Da34047F47B487BB83A7f9e4fB285D015bC";
        const newOrderData = {
          orderId,
          buyer,
          seller: "0xCc4C6048F1c3215EDa31BACBBFeD320edEf8Beb2",
          correspondingBank: "0x28000ddc8478C81633421bEF8E385394c6409DAD",
          status: "Confirmed",
        };

        // Save to localStorage
        const storedOrderData =
          JSON.parse(localStorage.getItem("LCData")) || [];
        storedOrderData.push(newOrderData);
        localStorage.setItem("LCData", JSON.stringify(storedOrderData));
      } else {
        setTransactionStatus("error");
        setErrorMessage(result.error || "Failed to set LC Agreement.");
      }
    } catch (error) {
      console.error("Error setting LC Agreement:", error);
      setTransactionStatus("error");
      setErrorMessage(error.message || "Failed to set LC Agreement.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Letter of Credit</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="seller">Seller Address:</label>
              <select
                className="form-control"
                id="seller"
                value={seller}
                onChange={(e) => setSeller(e.target.value)}
                required
              >
                <option value="">Select Seller</option>
                {sellers.map((seller) => (
                  <option key={seller.value} value={seller.value}>
                    {seller.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="correspondingBank">
                Corresponding Bank Address:
              </label>
              <select
                className="form-control"
                id="correspondingBank"
                value={correspondingBank}
                onChange={(e) => setCorrespondingBank(e.target.value)}
                required
              >
                <option value="">Select Bank</option>
                {banks.map((bank) => (
                  <option key={bank.value} value={bank.value}>
                    {bank.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="orderId">Order ID:</label>
              <input
                type="text"
                className="form-control"
                id="orderId"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={loading}
            >
              {loading ? "Processing..." : "Add"}
            </button>
          </form>

          {loading && (
            <div className="progress mt-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              ></div>
            </div>
          )}

          {transactionStatus === "success" && (
            <div className="alert alert-success mt-3" role="alert">
              LC Agreement set successfully!
            </div>
          )}
          {transactionStatus === "error" && (
            <div className="alert alert-danger mt-3" role="alert">
              Transaction failed. {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetLCAgreementForm;
