// src/components/GetDocumentIDForm.js
import React, { useState } from "react";
import { getDocumentID } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";

const GetDocumentIDForm = () => {
  const [orderId, setOrderId] = useState("");
  const [documentIds, setDocumentIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setDocumentIds([]);
    try {
      const result = await getDocumentID(orderId);
      setDocumentIds(result);
    } catch (error) {
      setError("Failed to get document IDs.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Documentation</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="orderId">Order ID:</label>
              <input
                type="text"
                className="form-control"
                id="orderId"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-3">
              View
            </button>
          </form>
          {loading && <div className="mt-3">Loading...</div>}
          {documentIds.length > 0 && (
            <div className="mt-3 text-success">
              <h5>Document IDs:</h5>
              <ul>
                {documentIds.map((id, index) => (
                  <li key={index}>{id}</li>
                ))}
              </ul>
            </div>
          )}
          {error && <div className="mt-3 text-danger">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default GetDocumentIDForm;
