import React, { useState, useEffect } from "react";
import { viewOrders } from "../api";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ViewOrdersForm = () => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchOrder = async () => {
      const orderId = JSON.parse(localStorage.getItem("OrderId"));
      if (!orderId) {
        setError("Order ID not found.");
        return;
      }

      setLoading(true);
      setError("");
      try {
        const result = await viewOrders(orderId);
        setOrders(result);
      } catch (error) {
        setError("Failed to view orders.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrder(); // Fetch order on component mount
  }, []);

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Purchase Order</h2>
        </div>
        <div className="card-body">
          {loading && <div className="mt-3">Loading...</div>}
          {orders && (
            <Table striped bordered hover className="mt-3">
              <tbody>
                <tr>
                  <td>
                    <strong>Order ID:</strong>
                  </td>
                  <td>{orders.orderID}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Seller:</strong>
                  </td>
                  <td>{orders.seller}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Buyer:</strong>
                  </td>
                  <td>{orders.buyer}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Item:</strong>
                  </td>
                  <td>{orders.item}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Order State:</strong>
                  </td>
                  <td>{orders.orderState ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Payment State:</strong>
                  </td>
                  <td>{orders.paymentState ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Description:</strong>
                  </td>
                  <td>{orders.description}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Quantity:</strong>
                  </td>
                  <td>{orders.quantity}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Verified by Inspection Company:</strong>
                  </td>
                  <td>{orders.isVerifiedByInspectionCompany ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Verified By Seller Country Customs:</strong>
                  </td>
                  <td>
                    {orders.isVerifiedBySellerCountryCustoms ? "Yes" : "No"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Verified By Buyer Country Customs:</strong>
                  </td>
                  <td>
                    {orders.isVerifiedByBuyerCountryCustoms ? "Yes" : "No"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Order:</strong>
                  </td>
                  <td>{orders.isOrder ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </Table>
          )}
          {error && <div className="mt-3 text-danger">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default ViewOrdersForm;
