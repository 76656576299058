import React, { useState, useEffect } from "react";
import { viewInvoices } from "../api";
import easyinvoice from "easyinvoice";
import "bootstrap/dist/css/bootstrap.min.css";

const ViewInvoicesForm = () => {
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchInvoice = async () => {
      const storedOrderId = JSON.parse(localStorage.getItem("OrderId"));
      if (storedOrderId) {
        setLoading(true);
        setError("");
        try {
          const result = await viewInvoices(storedOrderId);
          setInvoice(result);
        } catch (error) {
          setError("Failed to view invoices.");
        } finally {
          setLoading(false);
        }
      } else {
        setError("No order ID found.");
      }
    };

    fetchInvoice();
  }, []);

  const generatePDF = () => {
    if (!invoice) return;

    const data = {
      apiKey:
        "YueKqOvdIkRDl2O3HC2cIOiCxnaxm7Gs9xqcBVs2uQhwx0Nqvds3fdCBhRUx7C8X",
      mode: "production",
      products: [
        {
          quantity: 1,
          description: "Test product",
          taxRate: 6,
          price: invoice.unitPrice,
        },
      ],
      invoiceNumber: invoice.orderId,
      invoiceDate: new Date().toISOString().split("T")[0],
      company: {
        name: "Your Company Name",
        address: "Your Company Address",
        city: "Your City",
        country: "Your Country",
      },
      customer: {
        name: invoice.customerName,
        address: invoice.customerAddress,
        city: invoice.customerCity,
        country: invoice.customerCountry,
      },
      bottomNotice: "Thank you for your business!",
      subtotal: invoice.totalCost,
      discount: invoice.discount,
      total: invoice.costAfterDiscount,
    };

    easyinvoice.createInvoice(data, function (result) {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${result.pdf}`;
      link.download = `invoice_${invoice.orderId}.pdf`;
      link.click();
    });
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Quotation</h2>
        </div>
        <div className="card-body">
          {loading && <div className="mt-3">Loading...</div>}
          {invoice && (
            <div className="mt-3">
              <h3 className="text-center mb-4">Invoice</h3>
              <div className="row">
                <div className="col-md-6">
                  <h5>Invoice To:</h5>
                  <p>
                    <strong>Customer Name:</strong> {invoice.customerName}
                  </p>
                  <p>
                    <strong>Address:</strong> {invoice.customerAddress}
                  </p>
                  <p>
                    <strong>City:</strong> {invoice.customerCity}
                  </p>
                  <p>
                    <strong>Country:</strong> {invoice.customerCountry}
                  </p>
                </div>
                <div className="col-md-6 text-md-end">
                  <h5>Company Details:</h5>
                  <p>
                    <strong>Company Name:</strong> Accepire
                  </p>
                  <p>
                    <strong>Address:</strong> AK Str 90452
                  </p>
                  <p>
                    <strong>City:</strong> Nuremberg
                  </p>
                  <p>
                    <strong>Country:</strong> Germany
                  </p>
                </div>
              </div>
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Unit Price</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Test product</td>
                    <td>${invoice.unitPrice}</td>
                    <td>${invoice.quantity}</td>
                    <td>${invoice.totalCost}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table mt-4">
                <tbody>
                  <tr>
                    <td>
                      <strong>Discount:</strong>
                    </td>
                    <td>{invoice.discount}%</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total After Discount:</strong>
                    </td>
                    <td>${invoice.costAfterDiscount}</td>
                  </tr>
                </tbody>
              </table>
              {invoice.isConfirmed && (
                <div className="badge bg-success text-white mt-3">
                  <h5>Confirmed</h5>
                </div>
              )}
              <div className="badge bg-danger text-white mt-3">
                <h5>Not Confirmed</h5>
              </div>
              <button
                className="btn btn-success mt-3 float-end"
                onClick={generatePDF}
              >
                Export Invoice
              </button>
            </div>
          )}
          {error && <div className="mt-3 text-danger">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default ViewInvoicesForm;
