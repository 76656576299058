import React, { useState } from "react";
import { validateDocument } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";

const ValidateDocumentForm = () => {
  const [orderId, setOrderId] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const updateDocumentStatus = (orderId, newStatus) => {
    // Retrieve the current order data from localStorage
    const storedOrderData =
      JSON.parse(localStorage.getItem("DocumentData")) || [];

    // Find the index of the order to update
    const orderIndex = storedOrderData.findIndex(
      (order) => order.orderId === orderId
    );

    // If the order exists, update its status
    if (orderIndex !== -1) {
      storedOrderData[orderIndex].status = newStatus;

      // Save the updated order data back to localStorage
      localStorage.setItem("DocumentData", JSON.stringify(storedOrderData));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setTransactionStatus(null);

    try {
      const result = await validateDocument(orderId, documentId);
      if (result && result.status === "success") {
        setTransactionStatus("success");
        updateDocumentStatus(orderId, "Validated");
      } else {
        setTransactionStatus("error");
        setErrorMessage(result.error || "Failed to validate document.");
      }
    } catch (error) {
      console.error("Error validating document:", error);
      setTransactionStatus("error");
      setErrorMessage(error.message || "Failed to validate document.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Documentation</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="orderId">Order ID:</label>
              <input
                type="text"
                className="form-control"
                id="orderId"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="documentId">Document ID (IPFS):</label>
              <input
                type="text"
                className="form-control"
                id="documentId"
                value={documentId}
                onChange={(e) => setDocumentId(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={loading}
            >
              {loading ? "Processing..." : "Validate"}
            </button>
          </form>

          {loading && (
            <div className="progress mt-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              ></div>
            </div>
          )}

          {transactionStatus === "success" && (
            <div className="alert alert-success mt-3" role="alert">
              Document validated successfully!
            </div>
          )}
          {transactionStatus === "error" && (
            <div className="alert alert-danger mt-3" role="alert">
              Transaction failed. {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidateDocumentForm;
