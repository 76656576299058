// src/components/CheckOrderForm.js
import React, { useState } from "react";
import { orderExists } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";

const CheckOrderForm = () => {
  const [orderId, setOrderId] = useState("");
  const [exists, setExists] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    try {
      const result = await orderExists(orderId);
      setExists(result);
    } catch (error) {
      setError("Failed to check order existence.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Purchase Order</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="orderId">Order ID:</label>
              <input
                type="text"
                className="form-control"
                id="orderId"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-3">
              View
            </button>
          </form>
          {loading && <div className="mt-3">Loading...</div>}
          {exists !== null && (
            <div className="mt-3 text-success">
              <p>
                <strong>Order Exists: </strong> {exists ? "Yes" : "No"}
              </p>
            </div>
          )}
          {error && <div className="mt-3 text-danger">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default CheckOrderForm;
