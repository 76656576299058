import React, { useState } from "react";
import { createInvoice } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";

const CreateInvoiceForm = () => {
  const [orderId, setOrderId] = useState("");
  const [unitPrice, setUnitPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [privateKey, setPrivateKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setTransactionStatus(null);
    setErrorMessage("");

    try {
      const response = await createInvoice(
        orderId,
        unitPrice,
        discount,
        quantity,
        privateKey
      );
      if (response && response.status === "success") {
        setTransactionStatus("success");

        // Calculate the total price after discount
        const total = (unitPrice * quantity * (1 - discount / 100)).toFixed(2);
        var status = "Pending";
        // Create the order data object
        const newOrderData = {
          orderId,
          product: "Your Product Name",
          unitPrice,
          discount,
          quantity,
          total,
          status,
        };

        // Save to localStorage
        const storedOrderData =
          JSON.parse(localStorage.getItem("OrderData")) || [];
        storedOrderData.push(newOrderData);
        localStorage.setItem("OrderData", JSON.stringify(storedOrderData));

        // Optionally, save the OrderId separately
        localStorage.setItem("OrderId", JSON.stringify(orderId));
      } else {
        setTransactionStatus("error");
        setErrorMessage(response.error || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      setTransactionStatus("error");
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Quotation</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="orderId">Order ID:</label>
              <input
                type="text"
                className="form-control"
                id="orderId"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="unitPrice">Unit Price:</label>
              <input
                type="number"
                className="form-control"
                id="unitPrice"
                value={unitPrice}
                onChange={(e) => setUnitPrice(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="discount">Discount (%):</label>
              <input
                type="number"
                className="form-control"
                id="discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                className="form-control"
                id="quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={loading}
            >
              {loading ? "Processing..." : "Create"}
            </button>
          </form>

          {loading && (
            <div className="progress mt-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              ></div>
            </div>
          )}

          {transactionStatus === "success" && (
            <div className="alert alert-success mt-3" role="alert">
              Transaction successful!
            </div>
          )}
          {transactionStatus === "error" && (
            <div className="alert alert-danger mt-3" role="alert">
              Transaction failed. Please try again.
              <br />
              Error: {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateInvoiceForm;
