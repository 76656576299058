import { ethers } from "ethers";

const contractABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "orderId",
        type: "bytes32",
      },
    ],
    name: "SetSalesContract_Event",
    type: "event",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_documentID", type: "bytes32" }],
    name: "IsDocumentValid",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_orderID", type: "bytes32" },
      { internalType: "bytes32", name: "_documentID", type: "bytes32" },
    ],
    name: "addDocument",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_orderID", type: "bytes32" },
      { internalType: "int256", name: "_quantity", type: "int256" },
      { internalType: "string", name: "_item", type: "string" },
      { internalType: "string", name: "_description", type: "string" },
    ],
    name: "addOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "cancelOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "checkOrder",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "confirmFinancialAgreement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "confirmInvoice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "confirmOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_orderID", type: "bytes32" },
      { internalType: "uint256", name: "_unitPrice", type: "uint256" },
      { internalType: "uint256", name: "_discount", type: "uint256" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
    ],
    name: "createInvoice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "dealers",
    outputs: [
      { internalType: "address", name: "seller", type: "address" },
      { internalType: "address", name: "buyer", type: "address" },
      { internalType: "bytes32", name: "orderID", type: "bytes32" },
      { internalType: "bool", name: "isDeal", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "financialAgreementParties",
    outputs: [
      { internalType: "address", name: "buyer", type: "address" },
      { internalType: "address", name: "issuingBank", type: "address" },
      { internalType: "bytes32", name: "orderID", type: "bytes32" },
      { internalType: "bool", name: "exists", type: "bool" },
      {
        internalType: "bool",
        name: "isConfirmedByIssuingBank",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "getDocumentID",
    outputs: [{ internalType: "bytes32[]", name: "", type: "bytes32[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "getNumberOfDocuments",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "invoices",
    outputs: [
      { internalType: "uint256", name: "unitPrice", type: "uint256" },
      { internalType: "uint256", name: "totalCost", type: "uint256" },
      { internalType: "uint256", name: "discount", type: "uint256" },
      {
        internalType: "uint256",
        name: "CostAfterDiscount",
        type: "uint256",
      },
      { internalType: "bool", name: "isConfirmedByBuyer", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "lcParties",
    outputs: [
      { internalType: "address", name: "seller", type: "address" },
      { internalType: "address", name: "issuingBank", type: "address" },
      { internalType: "bytes32", name: "orderID", type: "bytes32" },
      {
        internalType: "address",
        name: "correspondingBank",
        type: "address",
      },
      { internalType: "bytes32", name: "LCID", type: "bytes32" },
      { internalType: "bool", name: "exists", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "orders",
    outputs: [
      { internalType: "bytes32", name: "orderID", type: "bytes32" },
      { internalType: "address", name: "seller", type: "address" },
      { internalType: "address", name: "buyer", type: "address" },
      { internalType: "string", name: "item", type: "string" },
      {
        internalType: "enum AccepireBT.OrderState",
        name: "orderState",
        type: "uint8",
      },
      {
        internalType: "enum AccepireBT.PaymentState",
        name: "paymentState",
        type: "uint8",
      },
      { internalType: "string", name: "description", type: "string" },
      { internalType: "bytes32", name: "quantity", type: "bytes32" },
      {
        internalType: "bool",
        name: "isVerifiedByInspectionCompany",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "isVerifiedBySellerCountryCustoms",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "isVerifiedByBuyerCountryCustoms",
        type: "bool",
      },
      { internalType: "bool", name: "isOrder", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_orderID", type: "bytes32" },
      { internalType: "address", name: "_issuingBank", type: "address" },
    ],
    name: "setFinancialAgreementParties",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_seller", type: "address" },
      {
        internalType: "address",
        name: "_correspondingBank",
        type: "address",
      },
      { internalType: "bytes32", name: "_orderID", type: "bytes32" },
    ],
    name: "setLCAgreement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_seller", type: "address" },
      { internalType: "bytes32", name: "_orderID", type: "bytes32" },
    ],
    name: "setSalesAgreement",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_orderID", type: "bytes32" },
      { internalType: "bytes32", name: "_documentID", type: "bytes32" },
    ],
    name: "validateDocument",
    outputs: [
      { internalType: "bytes32", name: "", type: "bytes32" },
      { internalType: "bytes32", name: "", type: "bytes32" },
      { internalType: "bool", name: "", type: "bool" },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "viewDealers",
    outputs: [
      {
        components: [
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "bytes32", name: "orderID", type: "bytes32" },
          { internalType: "bool", name: "isDeal", type: "bool" },
        ],
        internalType: "struct AccepireBT.dealer",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "viewInvoices",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "unitPrice", type: "uint256" },
          { internalType: "uint256", name: "totalCost", type: "uint256" },
          { internalType: "uint256", name: "discount", type: "uint256" },
          {
            internalType: "uint256",
            name: "CostAfterDiscount",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isConfirmedByBuyer",
            type: "bool",
          },
        ],
        internalType: "struct AccepireBT.Invoice",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_orderID", type: "bytes32" }],
    name: "viewOrders",
    outputs: [
      {
        components: [
          { internalType: "bytes32", name: "orderID", type: "bytes32" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "string", name: "item", type: "string" },
          {
            internalType: "enum AccepireBT.OrderState",
            name: "orderState",
            type: "uint8",
          },
          {
            internalType: "enum AccepireBT.PaymentState",
            name: "paymentState",
            type: "uint8",
          },
          { internalType: "string", name: "description", type: "string" },
          { internalType: "bytes32", name: "quantity", type: "bytes32" },
          {
            internalType: "bool",
            name: "isVerifiedByInspectionCompany",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isVerifiedBySellerCountryCustoms",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isVerifiedByBuyerCountryCustoms",
            type: "bool",
          },
          { internalType: "bool", name: "isOrder", type: "bool" },
        ],
        internalType: "struct AccepireBT.Order",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
const contractAddress = "0x56e2C533166a503b82b5a612f7A6C5BC1a1F0373";

// async function getBlockchain() {
//   if (typeof window.ethereum !== "undefined") {
//     const provider = new ethers.BrowserProvider(window.ethereum);
//     const signer = await provider.getSigner();
//     const contract = new ethers.Contract(contractAddress, contractABI, signer);
//     return { provider, signer, contract };
//   } else {
//     console.error("Ethereum object not found. Install MetaMask.");
//     return null;
//   }
// }

async function getBlockchain() {
  try {
    // const provider_Metamask = new ethers.providers.Web3Provider(
    //   window.ethereum
    // );
    const provider = new ethers.InfuraProvider(
      "sepolia",
      "0a2f3875dc2b4fb18870a40c8f62f816"
    );
    //const signer = provider.getSigner(); // Note: For read-only operations, you might not need a signer.
    // const contract = new ethers.Contract(
    //   contractAddress,
    //   contractABI,
    //   provider
    // ); // Use provider here for read-only operations

    // If you need to sign transactions, you'd need a private key or use a wallet
    // For demonstration, assuming you want to use a wallet:
    const sellerWallet = new ethers.Wallet(
      "6cd4036a6c9b8c084b44a1895b8b353defdbc972a2f6c05ae25197d57c3299c2",
      provider
    );
    const applicantWallet = new ethers.Wallet(
      "9807ccb1276d60bd789a4a9acfc3d25e2b34cab80f2069a86c5265f92cd26e13",
      provider
    );
    const issuingBankWallet = new ethers.Wallet(
      "8aa3b79fe1129e67025ff948aed881b259e56800c71d2b1f57bf8472f401b551",
      provider
    );
    const correspondingBankWallet = new ethers.Wallet(
      "9bb31cf64d207e33b1d66ca35459723b50159bb3c6dd444780b767c2c0fd4d07",
      provider
    );
    //const mnemonic = "your mnemonic phrase here";

    //const wallet = ethers.Wallet.fromMnemonic(mnemonic);
    //const wallet = ethers.Wallet.createRandom();
    const sellerContract = new ethers.Contract(
      contractAddress,
      contractABI,
      sellerWallet
    );
    const applicantContract = new ethers.Contract(
      contractAddress,
      contractABI,
      applicantWallet
    );

    const issuingBankContract = new ethers.Contract(
      contractAddress,
      contractABI,
      issuingBankWallet
    );

    const correspondingBankContract = new ethers.Contract(
      contractAddress,
      contractABI,
      correspondingBankWallet
    );

    return {
      provider,
      sellerContract,
      applicantContract,
      issuingBankContract,
      correspondingBankContract,
    };
  } catch (error) {
    console.error("Error connecting to the blockchain:", error);
    return null;
  }
}

export { getBlockchain };
