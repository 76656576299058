import React, { useState } from "react";
import { addOrder } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";

const AddOrderForm = () => {
  const [orderId, setOrderId] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [item, setItem] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setTransactionStatus(null);
    setErrorMessage("");

    try {
      const response = await addOrder(orderId, quantity, item, description);
      if (response && response.status === "success") {
        setTransactionStatus("success");

        const newOrderData = {
          orderId,
          item,
          quantity,
          description,
          status: "Pending",
        };

        // Save to localStorage
        const storedOrderData =
          JSON.parse(localStorage.getItem("POData")) || [];
        storedOrderData.push(newOrderData);
        localStorage.setItem("POData", JSON.stringify(storedOrderData));
      } else {
        setTransactionStatus("error");
        setErrorMessage(response.error || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error adding order:", error);
      setTransactionStatus("error");
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Purchase Order</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="orderId">Order ID:</label>
              <input
                type="text"
                className="form-control"
                id="orderId"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                className="form-control"
                id="quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="item">Item:</label>
              <input
                type="text"
                className="form-control"
                id="item"
                value={item}
                onChange={(e) => setItem(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="description">Description:</label>
              <input
                type="text"
                className="form-control"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={loading}
            >
              {loading ? "Processing..." : "Add"}
            </button>
          </form>

          {loading && (
            <div className="progress mt-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              ></div>
            </div>
          )}

          {transactionStatus === "success" && (
            <div className="alert alert-success mt-3" role="alert">
              Transaction successful!
            </div>
          )}
          {transactionStatus === "error" && (
            <div className="alert alert-danger mt-3" role="alert">
              Transaction failed. Please try again.
              <br />
              Error: {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddOrderForm;
