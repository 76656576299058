import React, { useState } from "react";
import { setFinancialAgreementParties } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";

const SetFinancialAgreementPartiesForm = () => {
  const [orderId, setOrderId] = useState("");
  const [issuingBank, setIssuingBank] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const issuingBanks = [
    {
      value: "0xC30130e00FB80cEd45c436c057Fe30e316e73ab1",
      label: "Issuing Bank A",
    },
    {
      value: "0xC30130e00FB80cEd45c436c057Fe30e316e73ab1",
      label: "Issuing Bank B",
    },
    {
      value: "0xC30130e00FB80cEd45c436c057Fe30e316e73ab1",
      label: "Issuing Bank C",
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setTransactionStatus(null);
    setErrorMessage("");

    try {
      const result = await setFinancialAgreementParties(orderId, issuingBank);
      if (result && result.status === "success") {
        setTransactionStatus("success");

        const newOrderData = {
          orderId,
          issuingBank,
          status: "Confirmed",
        };

        // Save to localStorage
        const storedOrderData =
          JSON.parse(localStorage.getItem("FinanceData")) || [];
        storedOrderData.push(newOrderData);
        localStorage.setItem("FinanceData", JSON.stringify(storedOrderData));
      } else {
        setTransactionStatus("error");
        setErrorMessage(
          result.error || "Failed to set financial agreement parties."
        );
      }
    } catch (error) {
      console.error("Error setting financial agreement parties:", error);
      setTransactionStatus("error");
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Financial Partnership</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="orderId">Order ID:</label>
              <input
                type="text"
                className="form-control"
                id="orderId"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="issuingBank">Issuing Bank Address:</label>
              <select
                className="form-control"
                id="issuingBank"
                value={issuingBank}
                onChange={(e) => setIssuingBank(e.target.value)}
                required
              >
                <option value="">Select Issuing Bank</option>
                {issuingBanks.map((bank) => (
                  <option key={bank.value} value={bank.value}>
                    {bank.label}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={loading}
            >
              {loading ? "Processing..." : "Add"}
            </button>
          </form>

          {loading && (
            <div className="progress mt-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              ></div>
            </div>
          )}

          {transactionStatus === "success" && (
            <div className="alert alert-success mt-3" role="alert">
              Financial agreement parties set successfully!
            </div>
          )}
          {transactionStatus === "error" && (
            <div className="alert alert-danger mt-3" role="alert">
              Transaction failed. {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetFinancialAgreementPartiesForm;
